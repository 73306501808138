import React from 'react';
import ReactDOM from 'react-dom/client';

// import './index.css';
import './Styles/Theme-IT.css';
import './Styles/BasicElements.css';
import './Styles/SURVEY.css';

import reportWebVitals from './reportWebVitals';
import {App} from "./ApplicationFrame";
import {SURVEY} from "./SURVEY";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <App><SURVEY /></App>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
