import React, {useContext, useEffect, useState} from 'react';
import {LanguageConsumer, LanguageContext, LanguageProvider, LanguageRefresher, Translation} from "./Translation";
import {LanguageSelect} from "./languageSelect";
import {setLocale} from "react-i18nify";

export const STATUS = {
    "unknowned": "unknowend",
    "loading": "loading",
    "loaded": "loaded",
    "complete": "complete",
    "failed": "failed"
}

export const App = props =>
    <LanguageProvider>
        <Translation>
            <LanguageSelect/>{ props.children }
        </Translation>
    </LanguageProvider>;