
const readCookie = () => {
    if( (""+document.cookie).length <= 0 )
        return {};

    return document.cookie
        .split(";")
        .filter( a => a.includes("=" ))
        .reduce( (acc,cur) => {
            const pair = cur.split("=");
            acc[pair[0]] = pair[1];
            return acc;
        }, {});
}
const saveCookie = (key,value) => {
    const cookie = readCookie();
    cookie[key] = value;

    document.cookie = Object.keys( cookie )
        .map( key => key + "=" + cookie[key])
        .join(";");
}
export const Cookie = {
    get: readCookie,
    set: saveCookie
}