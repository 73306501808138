import React, {useEffect, useState} from "react";
import {Questions} from "../index";


export const QuestionLoop = ({catalog, loopOver, response, onBack}) => {

    const [loop, setLoop] = useState(0);
    const [answers, setAnswers] = useState({});

    const onResponse = resp => {
        if( loop === loopOver.length -1 ) {
            response({...answers, [loopOver[loop]]: resp});
        } else {
            setAnswers({...answers, [loopOver[loop]]: resp});
            setLoop(loop + 1);
        }
    }

    return <>
        <div>{ loopOver.join(", ") + " => " + catalog[loop].question + " " + loopOver[loop] }</div>
        <Questions catalog={catalog} context={loopOver[loop]} response={onResponse} onBack={onBack} />
    </>
}