import {Cookie} from "../ApplicationFrame/Cookie";


export const Numbers = {

    isInRange: ( [min,max], number ) => min <= number && number <= max,

    isInBoundaries: ([p1,p2], point ) => Numbers.isInRange([p1.x, p2.x], point.x) && Numbers.isInRange([p1.y, p2.y], point.y),

    formatNumber: new Intl.NumberFormat(Cookie.get().lang, {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
    })
}