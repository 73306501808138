import React, {useEffect, useState} from 'react';
import { ReactComponent as Königin} from "../../Icons/Schach-Königin.svg";
import { ReactComponent as König} from "../../Icons/Schach-König.svg";
import { ReactComponent as Läufer} from "../../Icons/Schach-Läufer.svg";
import { ReactComponent as Pferd} from "../../Icons/Schach-Pferd.svg";
import { ReactComponent as Turm} from "../../Icons/Schach-Turm.svg";
import { ReactComponent as Bauer} from "../../Icons/Schach-Bauer.svg";
import {Numbers} from "../../helpers/Numbers";

const SYMBOL = {
    "Königin": <Königin />,
    "König": <König />,
    "Läufer": <Läufer />,
    "Pferd": <Pferd />,
    "Turm": <Turm />,
    "Bauer": <Bauer />,
}
export const QuestionCartesian = ({question, preAnswer, response, rules}) => {

    const [points, setPoints] = useState({});

    const dragStart = event => {
    }
    const dragOver = event => {
        event.dataTransfer.dropEffect = "dragging";
        event.dataTransfer.dropEffect = "move";
        event.preventDefault();
    }
    const onUpdate = (id, point) => {
        setPoints({...points, [id]: point });
    }
    useEffect( () => { response( points ); }, [points]);

    return <div className={"QuestionElement QuestionCartesian"}>
        <div className={"Question"}>{ question }</div>
        <div className={"CartesianFrame"}>
            <div className={"CartesianFigureSetup"}>
                <Figure rules={rules} id={1} symbol={SYMBOL.Königin} response={ point => onUpdate(1, point )} />
                <Figure rules={rules} id={2} symbol={SYMBOL.König} response={ point => onUpdate(2, point )} />
                <Figure rules={rules} id={3} symbol={SYMBOL.Läufer} response={ point => onUpdate(3, point )} />
                <Figure rules={rules} id={4} symbol={SYMBOL.Pferd} response={ point => onUpdate(4, point )} />
                <Figure rules={rules} id={5} symbol={SYMBOL.Turm} response={ point => onUpdate(5, point )} />
                <Figure rules={rules} id={6} symbol={SYMBOL.Bauer} response={ point => onUpdate(6, point )} />
            </div>
            <div className={"CartesianBoard"}/>
        </div>
    </div>
}

const BOUNDARIES = {
    topLeft: {x: 120, y: -30},
    bottomRight: {x: 780, y: 430}
}
const Figure = ({id, symbol, response, rules}) => {

    const boundYOffset = () => (id-1)*75;

    const ref = React.createRef();
    const [isOutside, setOutside] = useState(false);
    const [isDragging, setDragging] = useState(false);
    const [style, setStyle] = useState({});

    const [{ dx, dy }, setOffset] = useState({dx: 0, dy: 0});
    const [startPosition, setStartPosition] = useState({x: 0, y: 0});

    const handleMouseDown = event => {
        setDragging(true);
        setStyle({});
        // Get the current mouse position
        setStartPosition({
            x: event.clientX - dx,
            y: event.clientY - dy
        });

        ref.current.style.zIndex = 30;
    };

    const handleMouseMove = event => {
        const element = ref.current;

        if( ! element || ! isDragging )
            return;

        const dx = event.clientX - startPosition.x;
        const dy = event.clientY - startPosition.y;


        element.style.transform = `translate(${dx}px, ${dy}px)`;

        if( ! Numbers.isInBoundaries(
            [{x: BOUNDARIES.topLeft.x, y: BOUNDARIES.topLeft.y - boundYOffset()},
                    {x: BOUNDARIES.bottomRight.x, y: BOUNDARIES.bottomRight.y - boundYOffset()}],
            {x: dx, y: dy}) ) {
            ref.current.style.color = "red";
            setOutside( true );
        } else {
            ref.current.style.color = "currentcolor";
            setOutside( false );
        }

        setOffset({ dx, dy });
    };
    const handleMouseUp = event => {
        ref.current.style.zIndex = 1;
        setDragging(false);
        response( convertRelative2CartesianPoint({dx,dy}) )
    };
    const convertRelative2CartesianPoint = p => {

        const length = {
            final: {
                x: Math.abs(rules.limits.x[0]) + Math.abs(rules.limits.x[1]),
                y: Math.abs(rules.limits.y[0]) + Math.abs(rules.limits.y[1])
            },
            current: {
                x: BOUNDARIES.bottomRight.x - BOUNDARIES.topLeft.x,
                y: BOUNDARIES.bottomRight.y - BOUNDARIES.topLeft.y
            }
        }

        return {
            x: (p.dx - BOUNDARIES.topLeft.x) * length.final.x / length.current.x + Math.min(...rules.limits.x),
            y: -1. * ((p.dy - BOUNDARIES.topLeft.y + boundYOffset()) * length.final.y / length.current.y + Math.min(...rules.limits.y))
        };
    }

    const resetFigure = () => {
        setOutside(false);
        setOffset({dx: 0, dy: 0});
        setStyle({transform: "translate(0px, 0px)", color: "currentcolor"} );
    }

    useEffect(() => {
        if( ! isDragging && isOutside )
            resetFigure();
    }, [isDragging]);



    return <div className={"figure"}
                style={{top: id*30 +(id-1)*45, transform: style.transform, color: style.color}}
                ref={ref}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                width={40} height={40}>{ symbol }</div>
}