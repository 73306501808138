import axios from "axios";

export const isDevelopment = document.location.host === "localhost:3000";

const _Server = {

    onError: () => window.location.href = "https://error.super-dupa-pinky.de",

    download: (type, filename, data) => downloadFile(type, filename, data),

    getTranslationFor: (language) =>
        axios.get("/translations/"+language+".json"),

    getCompanyName: (companyID) =>
        axios.get( "/api/company.php?id="+companyID).catch(_Server.onError),

    getSurveyQuestions: (companyID) =>
        axios.get("/api/survey.php?id="+companyID).catch(_Server.onError),

    setSurveyAnswers: (companyID, data) => axios.post("/api/survey.answers.php", data, {headers: {"company_id": companyID}} )

}
const _DevelopmentServer = {

    download: (type, filename, data) => downloadFile(type, filename, data),

    getTranslationFor: (language) =>
        axios.get("/translations/"+language+".json").catch(_Server.onError),

    getCompanyName: (companyID) =>
        axios.get( "/api/Response_company.txt").catch(_Server.onError),

    getSurveyQuestions: (companyID) =>
        axios.get("/api/Response_survey.json").catch(_Server.onError),

    setSurveyAnswers: () => {

    }
}

const downloadFile = (type,filename,data) => {
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = filename;
    a.click();
}

export const Server = isDevelopment ? _DevelopmentServer : _Server;