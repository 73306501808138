
export const url = {

    getParams: () => {
        const queryString = "" + window.location;

        if( ! queryString.includes('?') )
            return {};

        const params = queryString.split('?')[1];

        return params
            .split("&")
            .reduce( (acc,cur) => {
                acc[cur.split("=")[0]] = cur.split("=")[1];
                return acc;
            }, {});
    }
}