import React, {useEffect, useState} from 'react';
import {translate} from "react-i18nify";

export const QuestionMultipleChoice = ({question, options, preAnswer, response}) => {

    const [selected, setSelected] = useState(preAnswer || []);

    useEffect(() => {
        selected.forEach( id => document.getElementById(question + "_" + id ).checked = true );
    }, [preAnswer]);

    useEffect(() => {
        response( selected);
    }, [selected]);

    const updateSelected = id => {
        const newList = [...selected];
        if( selected.includes(id) )
            setSelected( newList.filter( entry => entry !== id ) );
        else {
            newList.push( id );
            setSelected( newList )
        }
    }

    return <fieldset className={"QuestionElement QuestionMultipleChoice"}>
        <legend className={"Question"}>{ question }</legend>
        {
            options.map( option =>
                <div key={option.id} onChange={ () => updateSelected(option.id) } className={selected && ! selected.includes(option.id) ? "notchoosen answers" : "answers"}>
                    <input type={"checkbox"} id={question + "_" + option.id} value={option.id} name={question}  />
                    <label htmlFor={question + "_" + option.id}>
                        <div className={"icon"} selected={ ! selected.includes(option.id) }/>
                        <div className={"option"}>{ translate( "Sections.Questions." + option.text ) }</div>
                    </label>
                </div>)
        }
    </fieldset>
}