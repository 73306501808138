import React from 'react';
import {translate} from "react-i18nify";

import { ReactComponent as Trash} from "../Icons/trash.svg";
import { ReactComponent as Download} from "../Icons/download.svg";
import { ReactComponent as File} from "../Icons/file.svg";
import { ReactComponent as X} from "../Icons/close.svg";

export const Submit = ({onClick, disabled, className}) =>
    <button className={className} onClick={onClick} disabled={disabled} >{ translate("global.buttons.submit") }</button>
export const Cancel = ({onClick, disabled, className}) =>
    <button className={className} onClick={onClick} disabled={disabled} >{ translate("global.buttons.cancel") }</button>
export const Next = ({onClick, disabled, className}) =>
    <button className={className} onClick={onClick} disabled={disabled} >{ translate("global.buttons.next") }</button>
export const Back = ({onClick, disabled, className}) =>
    <button className={className} onClick={onClick} disabled={disabled} >{ translate("global.buttons.back") }</button>

export const Add = ({onClick, disabled, className}) =>
    <button className={className} onClick={onClick} disabled={disabled} >{ translate("global.buttons.add") }</button>
export const Delete = ({onClick, disabled, className}) =>
    <button className={className} onClick={onClick} disabled={disabled} >{ translate("global.buttons.delete") }</button>

export const DeleteSmall = ({onClick, disabled, className}) =>
    <button className={"ButtonSmall" + (className || "")} onClick={onClick} disabled={disabled} ><Trash /></button>

export const FileSmall = ({onClick, disabled, className}) =>
    <button className={"ButtonSmall" + (className || "")} onClick={onClick} disabled={disabled} ><File /></button>
export const DownloadSmall = ({onClick, disabled, className}) =>
    <button className={"ButtonSmall" + (className || "")} onClick={onClick} disabled={disabled} ><Download /></button>
export const XSmall = ({onClick, disabled, className}) =>
    <button className={"ButtonSmall" + (className || "")} onClick={onClick} disabled={disabled} ><X /></button>