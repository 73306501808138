import React, {useEffect, useState} from 'react';
import {translate} from "react-i18nify";

export const QuestionOneChoice = ({question, options, response, preAnswer}) => {

    const [selected, setSelected] = useState(preAnswer);

    useEffect(() => {
        response( selected);
    }, [selected]);

    return <fieldset className={"QuestionElement QuestionMultipleChoice"}>
        <legend className={"Question"}>{ question }</legend>
        {
            options.map( option =>
                <div key={option.id} onChange={ () => setSelected(option.id) } className={selected && selected !== option.id ? "notchoosen answers" : "answers"}>
                    <input type={"radio"} id={question + "_" + option.id} value={option.id} name={question} defaultChecked={option.id===selected} />
                    <label for={question + "_" + option.id}>
                        <div className={"icon"} selected={ selected === option.id }/>
                        <div className={"option"}>{ translate( "Sections.Questions." + option.text ) }</div>
                    </label>
                </div>)
        }
    </fieldset>
}